@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/Montserrat/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/Raleway/Raleway-Regular.ttf");
}

html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  overscroll-behavior: none;
  font-family: "Montserrat", sans-serif;
  background: url("../assets/images/bg2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.spinner {
  display: block;
  width: 80;
  height: 80;
  padding: 10;
}

/* Text */
h1,
h2,
h3,
h4,
h5 {
  font-family: "Raleway", sans-serif;
}

h5 {
  border-bottom: 1px solid #555;
  font-size: 1.1rem;
}

hr {
  width: 100%;
}

.text-center {
  text-align: center;
}

/* Layout */
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}

.full-width {
  width: 100%;
}

.hidden {
  display: none !important;
}

.center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.row {
  margin-left: -5px !important;
  margin-right: -5px !important;
}
div[class*="col"] {
  padding-left: 5px;
  padding-right: 5px;
}

.m-sm {
  margin: 10px;
}
.mt-sm {
  margin-top: 10px;
}
.mr-sm {
  margin-right: 10px;
}
.mb-sm {
  margin-bottom: 10px;
}
.ml-sm {
  margin-left: 10px;
}

.m-lg {
  margin: 40px;
}

/* Menus */
.menu {
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
}

#play-menu,
#game-over-menu {
  width: 400px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #fff;
  text-shadow: 0px 1px 10px #000, 1px 1px 4px #000;
}

#play-menu h1 {
  margin: 0;
}

#play-menu input {
  font-size: 18px;
  outline: none;
  border: none;
  border-bottom: 1px solid black;
  margin-bottom: 5px;
  padding: 3px;
  text-align: center;
}

#play-menu input:hover {
  outline: none;
}

/* In Game Styles */
#game-canvas {
  width: 100%;
  height: 100%;
  cursor: crosshair;
}

#header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 15px;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
}

#header p {
  margin: 0 10px;
}

#sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 220px;
  margin: 10px;
  color: #000;
  font-size: 0.9em;
}

.modal-title {
  border: none;
}

.game-menu {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.alert {
  position: absolute !important;
  top: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  margin: 10px;
  opacity: 0.8;
}

#skill-upgrader {
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 0.9em;
  color: #fff;
}

#disconnect-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

#disconnect-modal > div {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  padding: 15px 20px;
  background-color: white;
  border-radius: 5px;
  text-align: center;
}
